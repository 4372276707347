<template>
  <div class="text-hover-primary px-1" :disabled="isDisabled" @click="toggle">
    <i
      class="align-middle cursor-pointer"
      :class="[options.icon, isActive && 'text-primary']"
    />
  </div>
</template>

<script>
export default {
  name: "BaseTool",
  components: {},
  props: {
    editor: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  computed: {
    isActive: function () {
      return this.options.params
        ? this.editor.isActive(this.options.name, this.options.params)
        : this.editor.isActive(this.options.name);
    },
    isDisabled: function () {
      if (!this.options.toggleKey) {
        return false;
      }
      return this.options.params
        ? this.editor
            .can()
            .chain()
            .focus()
            [this.options.toggleKey](this.options.params)
            .run()
        : this.editor.can().chain().focus()[this.options.toggleKey]().run();
    }
  },
  mounted() {},
  methods: {
    toggle() {
      if (!this.options.toggleKey) {
        return false;
      }
      this.options.params
        ? this.editor
            .chain()
            .focus()
            [this.options.toggleKey](this.options.params)
            .run()
        : this.editor.chain().focus()[this.options.toggleKey]().run();
    }
  }
};
</script>
